import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 
import './App.css';
import { ContextProvider } from './context/MyContext';
import Header from './components/Header'
import WeeklyForm from './components/forms/WeeklyForm';
import BiweeklyForm from './components/forms/BiweeklyForm';
import MonthlyForm from './components/forms/MonthlyForm';
import DeepCleanForm from './components/forms/DeepClean';
import OneTimeFloorCleanForm from './components/forms/OneTimeFloorClean';
import OneTimeFloorCleanAds from './components/forms/OneTimeFloorCleanAds';
import DeepCleanGiveaway from './components/forms/DeepCleanGiveaway';
import CleanGiveaway from './components/forms/CleanGiveaway';
import OneTimeDeepCleanAds from './components/forms/OneTimeDeepCleanAds';
import WindowCleaning from './components/forms/WindowCleaning';
import MembershipForm from './components/forms/MembershipForm';
import FacebookAd from './components/forms/FacebookAd';

/*
  Notes:
  - To add new services, create a functional component in the forms folder
    call it whatever the name of the service is and add it to the routes below
    you will also need to add it to the Constants file

  - Could also just change the functionality to just have one form and have it pull the service from url.
    The way it is now allows for different services to have custom variables passed through props if ever needed.

  - A lot of customizable variables are in Constants

  Functionality:
  1) Start at the service components in the forms folder, all of these call the ContactInput component in contactFormLogic folder
    
  2) ContactInput handles the first half of the process which creates the customer
    
  3) Once the customer is created the form component then switches over to the AppointmentInput component in appointmentFormLogic
    This handles the rest of the process; grabbing available spots, payment information, then setting up the appointment
    
*/
const App = () => {
  const options = {
    autoConfig: true,
    debug: false
  }



  return (
    <ContextProvider>
      <Router>
        <Header/>
        <div className="App mainContainer container d-flex flex-column justify-content-between">
          <Routes>
            <Route exact path="/" element={<WeeklyForm/>}/>
            <Route exact path="/weekly" element={<WeeklyForm/>}/>
            <Route exact path="/biweekly" element={<BiweeklyForm/>}/>
            <Route exact path="/monthly" element={<MonthlyForm/>}/>
            <Route exact path="/deepClean" element={<DeepCleanForm/>}/>
            <Route exact path="/deep-clean" element={<OneTimeDeepCleanAds/>}/>
            <Route exact path="/oneTimeFloorClean" element={<OneTimeFloorCleanForm/>}/>
            <Route exact path="/floorclean" element={<OneTimeFloorCleanAds /> }/>
            <Route exact path="/floorgiveaway" element={ <DeepCleanGiveaway/> }/>
            <Route exact path="/cleangiveaway" element={ <CleanGiveaway/> }/>
            <Route exact path="/windows" element={ <WindowCleaning/> }/>
            <Route exact path="/membership" element={ <MembershipForm /> }/>
            <Route exact path="/monthly-promo" element={ <FacebookAd /> }/>
          </Routes>
        </div>
      </Router>
    </ContextProvider>
  );
}

export default App;
