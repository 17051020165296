/*
  Description: these are all helper functions used by the Context file in this same folder
  
*/
export const filterServiceTypes = (serviceTypes, isFloorClean) => {
    let typesObj = {};
    serviceTypes.forEach(service => {
        if(!service.description.includes('Online') && service.category !== "DEMO"){
          let area = null;
          if(isFloorClean){
            let split = service.description.split(' ');
            area = split[3];
          }else{
            let sqFeetArr = service.description.match(/[0-9]{4}/);
            if(sqFeetArr){
              area = sqFeetArr[0];
            }
          }
          if(area){
            if (typesObj[area]) {
              typesObj[area].push(service);
            } else {
              let tmpArr = [];
              tmpArr.push(service);
              typesObj[area] = tmpArr;
            }
          }
        }
    });

    return typesObj
}


/*
  Grab discount information from the url and validates it
*/
export const grabDiscounts = () => {
  let typeAmount = window.getDiscountParams();
  let valid = true, type = null, amount = null;


  if(typeAmount){
    let typeArr = typeAmount.split('-');
    if(typeArr && typeArr.length == 2){
      type = typeArr[0];
      if((type === 'p' || type === 'a') && !isNaN(typeArr[1])){
        amount = Number(typeArr[1]);
        if(amount < 0){
          valid = false;
        }
        if(type === 'p' && amount > 99 ){
          valid = false;
        }  
      }else{
        valid = false;
      }
    }else{
      valid = false;
    }
  }else{
    valid = false;
  }

  let discountObject = {valid, type,amount};

  return discountObject;
}