import React from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

/*
    Description: Predictive Addresss input component
*/

const AddressInput = ({addressObject, setAddressObject}) => {

    const searchOptions = {
        location: new window.google.maps.LatLng(33.448376, -112.074036),
        radius: 160000,
        types: ["address"]
    };

    const handleAddressChange = addr => {
        if((addr.length - addressObject.fullAddress.length) > 1){
            handleAddressSelect(addr);
        }
        setAddressObject({...addressObject, fullAddress: addr})
    }

    const handleAddressSelect = addr => {
        geocodeByAddress(addr)
        .then(res => {
            let tmpAddressObject = res[0];
            let components = tmpAddressObject.address_components;
            let address = "",
            city = "",
            state = "",
            zip = "",
            lat = "",
            lng = "";
            components.forEach(component => {
            let type = component.types[0];
            let value = component.short_name;
            if (type === "street_number") {
                address = address === "" ? value : `${value} ${address}`;
            } else if (type === "route") {
                address = `${address} ${value}`;
            } else if (type === "locality") {
                city = value;
            } else if (type === "administrative_area_level_1") {
                state = value;
            } else if (type === "postal_code") {
                zip = value;
            }
            });
            getLatLng(tmpAddressObject).then(res2 => {
            lat = res2.lat;
            lng = res2.lng;
            setAddressObject({
                fullAddress: `${address}, ${city}, ${state} ${zip}`,
                streetAddress: address,
                city: city,
                state: state,
                zip: zip,
                lat,
                lng
            });
            });
        })
        .catch(err => {console.log(err)});
    }
  return (
    <PlacesAutocomplete
        value={addressObject.fullAddress}
        onChange={handleAddressChange}
        onSelect={handleAddressSelect}
        shouldFetchSuggestions={addressObject.fullAddress.length > 3}
        searchOptions={searchOptions}>
            {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
            }) => (
            <div>
                <input
                 key={suggestions}
                {...getInputProps({
                    placeholder: "Address",
                    className: "inputFields address"
                })}
                />
                <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                    const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                    <div
                        {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                        })}
                    >
                        <span>{suggestion.description}</span>
                    </div>
                    );
                })}
                </div>
            </div>
            )}
    </PlacesAutocomplete>
  )
}

AddressInput.propTypes = {
    addressObject: PropTypes.object,
    setAddressObject: PropTypes.func
}

export default AddressInput