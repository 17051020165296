import React, { Fragment } from 'react'
import { useContext, useState, useEffect, useRef } from 'react'
import MyContext from '../../context/MyContext'
import * as Constants from '../../Constants'
import { calculateRecurringVariable } from '../contactFormLogic/Helpers'
import DatePicker from "react-datepicker";
import "../../Calendar.css"
import axios from 'axios'
import * as Helpers from './Helpers';
import * as Snippets from './Snippets'
import Cards from '../Cards'

/*
    Description: Second half of the process, this handles grabbing available dates and spots,
        grabbing payment information, then setting up the appointment

    Notes:
    - Uses helpers from Helpers.js and Snippets.js to keep this cleaner

    Functionality:
         onLoad - getInvalidDates() to grab all available spots

         1 - beginApptProcess()

         2 - validated()

         3 - createAppointment()
*/

const AppointmentInput = ({ title, description}) => {
    const {customer, futureDate, isFloorClean, serviceType, selectedService, discountObject, setContext, loading} = useContext(MyContext);
    const recurringVariableObj = calculateRecurringVariable(serviceType);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [monthsInState, setMonthsInState] = useState([]);
    const [spotsObj, setSpotsObj] = useState({});
    const [datesArray, setDatesArray] = useState([])
    const [selectedSpot, setSelectedSpot] = useState({})
    const [morningSpots, setMorningSpots] = useState({});
    const [afternoonSpots, setAfternoonSpots] = useState({});
    const [selectedDateString, setSelectedDateString] = useState('');
    const [firstSlotOpen, setFirstSlotOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [agreement1, setAgreement1] = useState(false);
    const [agreement2, setAgreement2] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isCollectSetup, setIsCollectSetup] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [buttonText, setButtonText] = useState('Book Appointment');
    const refVar = useRef({});
    refVar.spot = selectedSpot;
    refVar.notes = notes;

    console.log(discountObject);


    useEffect(() => {

        if(!isCollectSetup){
            window.setupCollect(validated, addError);
            setIsCollectSetup(true);
        }
        if(Constants.onlyShowAvailableDays && datesArray.length == 0){
            getInvalidDates(new Date(), futureDate);
            // setContext(Constants.SET_LOADING, false);
        }
    },[selectedSpot])

    /*
        Main function that calls api to retrieve available time spots
     */
    const getInvalidDates = (startDate, endDate) => {
        let arrContainer = [], openSpots = [], openDates = [], spotsToExclude = [], postArray = [];
        let tmpSpotsObj = spotsObj;
        let currentDateString = Helpers.getFormattedDate(startDate)
        let futureDateString = Helpers.getFormattedDate(endDate)
        setMonthsInState([...monthsInState, startDate.getMonth()]);

        let params = Helpers.paramBuilder({apiCanSchedule: 1});
        let postUrl = `${Constants.routesUrl}/spotSearch/${params}/${currentDateString}/${futureDateString}/${isFloorClean}`;
        axios.post(postUrl)
        .then(res => {
            let spotIds = res.data.spotIDs;
            let size = spotIds.length;
            let loopsRequired = Math.ceil(size/1000);
            let startIndex = 0;
            let sliceIndex = 1000;
            for(var x=0; x < loopsRequired; x++){
                let tmpArr = spotIds.slice(startIndex, sliceIndex);
                arrContainer.push(tmpArr);
                startIndex += 1000;
                sliceIndex += 1000;
            }
        })
        .then(() => {
            postArray = arrContainer.map(spotArray => {
                let dataObj = {
                    spotIDs: spotArray,
                    maxDistance: Constants.maxDistance,
                    latitude: customer.lat,
                    longitude: customer.lng
                };
                params = Helpers.paramBuilder(dataObj);
                postUrl = `${Constants.routesUrl}/getSpot/${params}`;
                return axios.post(postUrl).then(res => {
                    res.data.spots.forEach(spot => {
                        if(spot.open === '' && spot.currenAppointmentDuration){
                            let startingId = spot.spotID;
                            let capacity = spot.spotCapacity == 0 ? 19 : spot.spotCapacity;
                            let duration = spot.currenAppointmentDuration;
                            let range = Math.ceil(duration/capacity);
                            for(let x=0; x<range; x++){
                                spotsToExclude.push(startingId.toString());
                                startingId++;
                            }
                        }
                    })
                })
            })
        })
        .then(() => {
            return axios.all(postArray);
        })
        .then(() => {
            let index = 0;
            arrContainer.forEach(spotArray => {
                let dataObj = {
                    spotIDs: spotArray,
                    maxDistance: Constants.maxDistance,
                    latitude: customer.lat,
                    longitud: customer.lng
                };

                params = Helpers.paramBuilder(dataObj);
                postUrl = `${Constants.routesUrl}/getSpot/${params}`;
                axios.post(postUrl).then(res => {
                    res.data.spots.forEach(spot => {
                        if(spot.open === '1' && Helpers.validateSpot(spot, spotsToExclude, selectedService)){
                            if(openSpots.indexOf(spot.date) === -1){
                                openSpots.push(spot.date);
                                let tmpDate = new Date();
                                let splitDate = spot.date.split('-');
                                tmpDate.setFullYear(
                                    splitDate[0],
                                    splitDate[1] - 1 ,
                                    splitDate[2]
                                );
                                openDates.push(tmpDate);
                            }

                            if(tmpSpotsObj[spot.date]){
                                let tmpObj = tmpSpotsObj[spot.date];
                                let tmpArr = []
                                tmpArr.push(spot);
                                if(Helpers.isAfternoon(spot)){
                                    if(tmpObj.afternoonObj[spot.start]){
                                    tmpObj.afternoonObj[spot.start].push(spot)
                                    }else{
                                    tmpObj.afternoonObj[spot.start] = tmpArr;
                                    }
                                }else{
                                    if(tmpObj.morningObj[spot.start]){
                                        tmpObj.morningObj[spot.start].push(spot)
                                    }else{
                                        tmpObj.morningObj[spot.start] = tmpArr;
                                    }
                                }
                                tmpSpotsObj[spot.date] = tmpObj;
                            }else{
                                let morningObj = {}, afternoonObj = {};
                                let tmpArr = [];
                                tmpArr.push(spot)
                                if(Helpers.isAfternoon(spot)){
                                    afternoonObj[spot.start] = tmpArr; 
                                }else{
                                    morningObj[spot.start] = tmpArr; 
                                }
                                tmpSpotsObj[spot.date] = {morningObj, afternoonObj};
                            }
                        }
                    })
                    setDatesArray([...datesArray, ...openDates]);
                    setSpotsObj(tmpSpotsObj);

                    if(index === 0){
                        setContext(Constants.SET_LOADING, false);
                        window.scrollTo(0,0);
                        index++;
                    }
                })
            })
        })
    }

    /*
        1) Function to start payment and appointment process
     */
    const beginApptProcess = (e) => {
        e.preventDefault();
        if(!customer.customerID){
            alert("error in customer selection, please contact us for assistance");
        }else if(!selectedSpot.spotID){
            alert("You must select a time slot in order to proceed");
        }else if(!agreement1 || !agreement2){
            alert("You must agree to the terms in order to proceed");
        }else{
            setButtonText('Loading...');
            setContext(Constants.SET_LOADING, true);
            window.validateCard();
        }
    }

    /*
        2) Once the card is validated, a token is returned, we use this to continue the process
            - create a payment profile for the customer
            - then create an appointment
    */
    const validated = (res) => {
        if(res.token){
            let dataObj = {
                customerID: customer.customerID,
                CreditCardToken: res.token
            };

            let params = Helpers.paramBuilder(dataObj);
            let postUrl = `${Constants.routesUrl}/createPaymentProfile/${params}`;
            axios.post(postUrl)
            .then(res => {
                if(res.data.success){
                    createAppointment();
                }else{
                    addError(res.data.errorMessage);
                    setContext(Constants.SET_LOADING, false);
                }
            })
            .catch(err => {
                addError('error creating customer payment profile');
                setContext(Constants.SET_LOADING, false);
            })
        }
    }

    /*
        3) This is the last step in the process, this function handles everything after the payment profile is created
            It makes API calls to updateLead, update the customer, create the payment, then create the appointment.
            At the end it will send users to the success page.
    */
    const createAppointment = () => {
        setSelectedSpot(refVar.spot);
        setNotes(refVar.notes);

        let splitArr = customer.subscriptionIDs.split(',');
        let subscription = {}, updatedCustomer = {};
        let dataObj = {
            subscriptionID: splitArr[splitArr.length - 1],
            status: 1
        }

        let params = Helpers.paramBuilder(dataObj);
        let postUrl = `${Constants.routesUrl}/updateLead/${params}`;

        axios.post(postUrl)
        .then(res => {
            //console.log('updateLead response');
            //console.log(res);
            if(res.data.success){
                return axios.post(`${Constants.routesUrl}/customer/${customer.customerID}`);
            }else{
                addError('Failed updating lead');
                Promise.reject('Failed to create appointment');
            }
        })
        .then(res => {
            //console.log('customer response');
            //console.log(res);
            if(res.data.customer){
                updatedCustomer = res.data.customer;
                let customerSubs = updatedCustomer.subscriptions;
                subscription = customerSubs[customerSubs.length - 1];
                let dataObj = {
                    customerID: updatedCustomer.customerID,
                    duration: selectedService.defaultLength,
                    spotID: refVar.spot.spotID,
                    start: refVar.spot.start,
                    end: Helpers.calculateFinishTime(refVar.spot.start, selectedService.defaultLength),
                    notes: refVar.notes,
                    type: selectedService.typeID,
                    subscriptionID: subscription.subscriptionID
                };
                params = Helpers.paramBuilder(dataObj);
                postUrl = `${Constants.routesUrl}/createAppt/${params}`;
                return axios.post(postUrl);
            }else{
                addError('Failed to retrieve updated customer');
                Promise.reject('Failed to retrieve updated customer');
            }
        })
        .then(res => {
            //console.log('appointment response');
            //console.log(res);
            if(res.data && res.data.success){
                let paymentAmount = selectedService.defaultInitialCharge;

                if(discountObject.valid){
                    if(discountObject.type === 'p'){
                        paymentAmount = selectedService.defaultInitialCharge - (selectedService.defaultInitialCharge * (discountObject.amount/100));
                    }else if(discountObject.type === 'a'){
                        paymentAmount = selectedService.defaultInitialCharge - discountObject.saleAmount;
                    }
                }

                dataObj = {
                    customerID: updatedCustomer.customerID,
                    notes: refVar.notes,
                    subscriptionID: subscription.subscriptionID,
                    doCharge: 1,
                    paymentMethod: 3,
                    amount: paymentAmount
                };

                params = Helpers.paramBuilder(dataObj);
                postUrl = `${Constants.routesUrl}/createPayment/${params}`;
                return axios.post(postUrl);
            }else{
                addError('Failed to create appointment');
                Promise.reject('Failed to create appointment');
            }
        })
        .then(res => {
            //console.log('createdPayment');
            //console.log(res);
            if(res.data.success){
                params = Helpers.paramBuilder({subscriptionID: subscription.subscriptionID, emailCustomer: 1});
                postUrl = `${Constants.routesUrl}/createContract/${params}`;
                return axios.post(postUrl);
            }else{
                addError('Failed to create payment');
                Promise.reject('Failed to create payment');
            }
        })
        .then(res => {
            setContext(Constants.SET_LOADING, false);
            Snippets.sendEmail(customer, selectedService);
            window.location.assign("http://theperfectclean.com/your-scheduled/")
        })
    }

    const addError = message => {
        setErrors([...errors, message])
        setTimeout(() => setErrors([]), 3000);
    }

    /*
        Event Handlers
    */
    const onDateChange = date => {
        setSelectedDate(date);
        setSelectedSpot({});
        setSelectedDateString(`${Constants.monthNames[date.getMonth()]} ${date.getDate()}`);

        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();
        var dateString = `${date.getFullYear()}-${month.length === 1 ? `0${month}` : month}-${day.length === 1 ? `0${day}` : day}`;
        if(spotsObj[dateString]){
            let tmpObj = spotsObj[dateString];

            if(tmpObj.morningObj["09:00:00"]){
                if(tmpObj.morningObj["09:00:00"].length > 0){
                    setFirstSlotOpen(true);
                }else{
                    setFirstSlotOpen(false);
                }
            }else{
                setFirstSlotOpen(false);
            }
            setMorningSpots(tmpObj.morningObj);
            setAfternoonSpots(tmpObj.afternoonObj);
        }else{
            this.setState({morningSpots: {}, afternoonSpots: {}});
        }
    }

    const onMonthChange = date => {
        let startMonth = new Date(date.getFullYear(), date.getMonth(), 2);
        let endMonth = new Date(date.getFullYear(), date.getMonth() + 1, 10);
        if(monthsInState.indexOf(date.getMonth()) === -1){
            setContext(Constants.SET_LOADING, true);
            getInvalidDates(startMonth, endMonth);
        }
    }


  return (
      <div className="scheduleContainer row">
       <div className="service-selection">
            <img src="https://theperfectclean.com/wp-content/uploads/2024/01/step2.jpg" class="service-image" alt="" />
            <div className="service-image-container"></div>
            <div className="service-information">
                {/* <img className="service-rating" src="https://theperfectclean.com/wp-content/uploads/2023/09/stars.webp" alt="" /> */}
                {/* <p className="serviceTitle">{title}</p>
                <p className="service-description">{description}</p> */}
            </div>
        </div>
            { loading ? 
                <p class="appointment-loading">Searching for Availability...</p>
                :
                <>
                    <h3 className="title">Select Date/Time Window</h3>
                    <div className="appointmentContainer">
                        <DatePicker
                            inline
                            selected={selectedDate}
                            minDate={new Date()}
                            includeDates={datesArray}
                            filterDate={Helpers.isWeekDay}
                            onChange={onDateChange}
                            onMonthChange={onMonthChange}
                        />
                    </div>
                </>
            }
            {selectedDateString !== '' && (
                <div className='appointmentContainer'>
                    <h3 className="title">
                        Availability{' '}
                        {selectedDateString}
                    </h3>
                    <p className="light">
                        When scheduling an appointment there is always a 2 hour arrival time window
                    </p>
                    {Snippets.getAvailableTimeSlots(true, morningSpots, afternoonSpots, selectedSpot, firstSlotOpen, setSelectedSpot)}
                    {Snippets.getAvailableTimeSlots(false, morningSpots, afternoonSpots, selectedSpot, firstSlotOpen, setSelectedSpot)}
                </div>
            )}

            <div className="appointmentNotesContainer">
                <h3 className="timeOfDay">Appointment Notes</h3>
                <textarea name="notes" value={notes} onChange={(e) => setNotes(e.target.value)}
                    className='appointmentTextArea'
                    placeholder='Please enter the amount of bedrooms and bathrooms in your home'
                />
            </div>

            <div className="paymentsContainer">
                <h3 className="paymentTitle">Add a payment method to book your appointment</h3>
                <div id="ccnumber"/>
                <div className="half-width-container">
                    <div id="ccexp" className="half"/>
                    <div id="cvv" className="half"/>
                </div>
                <Cards/>
            </div>

            <div className="checkboxContainer">
                <label className="agreementCheckbox">
                    I authorize Clean to store this card for future service until
                    I cancel this authorization
                    <input type="checkbox" name="agreement1" onChange={(e) => setAgreement1(e.target.checked)} />
                    <span className="checkmark"></span>
                </label>
                <label className="agreementCheckbox">
                    I have read and agree to the <a href="https://theperfectclean.com/cancellation-policy/" target="_blank" rel="noopener" className="cancellationPolicy">cancellation policy.</a>
                    <input type="checkbox" name="agreement2" onChange={(e) => setAgreement2(e.target.checked)} />
                    <span className="checkmark"></span>
                </label>
            </div>

            {Snippets.getErrors(errors)}

            <div className="completeContainer">
                <p>Order Summary</p>
                {Helpers.isOneTimeCharge(serviceType) ? 
                    <Fragment>
                        {/* <div className="first-clean-container">
                            <p>One Time Charged:</p>
                            <p>{selectedService.defaultCharge}</p>
                        </div> */}
                        {/* <div className="first-clean-container">
                            <p>One Time Charge:</p>
                            <p>{Snippets.getInitialPrice(discountObject, selectedService)}</p>
                        </div> */}
                        <div className="first-clean-container">
                            <p>One Time Charge:</p>
                            <p>{Snippets.getInitialPrice(discountObject, selectedService)}</p>
                        </div>
                        {/* <div className="initialService tpc-flex"><span>One Time Charge: </span> <span className="original-price"> {selectedService.defaultCharge}</span>{Snippets.getInitialPrice(discountObject, selectedService)}</div> */}
                    </Fragment>
                    :
                    <>
                    <div className="first-clean-container">
                        <p>Due Today:</p>
                        <p>{Snippets.getInitialPrice(discountObject, selectedService)}</p>
                    </div>

                    <div className="first-clean-container">
                        <p>{serviceType === 'MEMBERSHIP' ? 'Monthly' : recurringVariableObj.term} Price:</p>
                        <div className="breakdown-container">
                            <p class="d-inline-block">${selectedService.defaultCharge} / {serviceType === 'MEMBERSHIP' ? 'Monthly' : recurringVariableObj.term}</p>
                            <p class="align-right service-description"> Starting in {serviceType ? recurringVariableObj.startingCount : ''} days after your first appointment</p>
                        </div>
                    </div>
                    </>
                    }
            </div>
                <div className="orderSummaryConfirmContainer">
                    <input type="button" value={buttonText} className="completeOrder" id="" onClick={e => beginApptProcess(e)}/>
                </div>
      </div>
  )
}

export default AppointmentInput