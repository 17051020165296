import axios from "axios";
import { createContext, useEffect, useReducer } from "react";
import { filterServiceTypes, grabDiscounts } from "./HelperFunctions";
import * as Constants from '../Constants';
import contextReducer from "./ContextReducer";

const MyContext = createContext();

/*
    Notes: Standard Context setup, moved a lot of the functions to the HelperFunctions.js file in this same folder
*/

export const ContextProvider = ({children}) => {
    const initialState = {
        loading: false,
        serviceType: '',
        serviceTypes: [],
        selectedService: null,
        isFloorClean: false,
        distinctAreas: [],
        discountObject: {},
        futureDate: new Date(),
        customer: {}
    }

    const [state, dispatch] = useReducer(contextReducer, initialState);

    useEffect(() => {
        let currentDate = new Date();
        let futureDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 10);
        setContext(Constants.SET_FUTURE_DATE, futureDate);
        setContext(Constants.SET_DISCOUNT, grabDiscounts());
    }, [])
    
    
    /*
        This function is called on component load to grab the serviceTypes
    */
    const fetchServiceTypes = async(serviceType) => {
        try {
            setContext(Constants.SET_LOADING, true);
            let isFloorClean = serviceType.toUpperCase().includes('FLOOR CLEAN');
            let postUrl = `${Constants.routesUrl}/serviceTypes/${serviceType}/${isFloorClean}`
            
            setContext(Constants.SET_SERVICE_TYPE, serviceType);
            setContext(Constants.SET_IS_FLOOR_CLEAN, isFloorClean);
            
            const res = await axios.post(postUrl);
            
            let types = filterServiceTypes(res.data, isFloorClean);
            setContext(Constants.SET_SERVICE_TYPES, types);
            setContext(Constants.SET_LOADING, false);
        } catch (error) {
            console.log('Error retrieving service types:');
            console.log(error);
        }
    }


    const fetchWindowService = async(serviceType) => {
        try {
            setContext(Constants.SET_LOADING, true);
            let postUrl = `${Constants.routesUrl}/serviceTypes/${serviceType}/false`
            
            setContext(Constants.SET_SERVICE_TYPE, serviceType);

            const res = await axios.post(postUrl);
            console.log(res.data[0]);
            setContext(Constants.SET_SERVICE_TYPES, res.data);
            setContext(Constants.SET_SELECTED_SERVICE, res.data[0]);
            setContext(Constants.SET_LOADING, false);
        } catch (error) {
            console.log('Error retrieving service types:');
            console.log(error);
        }
    }

    const setContext = (type, payload) => {
        dispatch({type, payload})
    }


    return <MyContext.Provider value = {{
        ...state,
        fetchServiceTypes,
        fetchWindowService,
        setContext
    }}>
        {children}
    </MyContext.Provider>

}

export default MyContext;