import React, { Fragment } from 'react'
import { useEffect, useContext } from 'react'
import MyContext from '../../context/MyContext'
import * as Constants from '../../Constants'
import AppointmentInput from '../appointmentFormLogic/AppointmentInput'
import ContactInput from '../contactFormLogic/ContactInput'


/*
  Notes: This is the template you'd need to create a new Service
    copy copy this form over and rename to whatever the new service is
*/

const WeeklyForm = () => {
  const {serviceTypes, fetchServiceTypes, customer} = useContext(MyContext)

  useEffect(() => {
    if(serviceTypes.length == 0){
      fetchServiceTypes(Constants.WEEKLY);
    }
  }, [])

  return (
    <Fragment>
      {!customer.customerID ? <ContactInput title={'Weekly Services'} description={`For busy households that are full of life and fun!`}/> : <AppointmentInput title={'Weekly Services'} description={`For busy households that are full of life and fun!`}/>}
    </Fragment>
  )
}

export default WeeklyForm