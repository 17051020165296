import loading from '../assets/loading.gif'


const Loading = () => {

  return (
      <img src={loading} alt="loading" style={{width: '33%', margin: 'auto', display: 'block'}}/>
  )
}

export default Loading