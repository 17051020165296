export const baseUrl = 'https://perfect-schedule.herokuapp.com';

export const routesUrl = `${baseUrl}/pestRoutes`;
//export const routesUrl: 'https://perfect-schedule.herokuapp.com/pestroutes',
//export const emailUrl: "https://perfect-schedule.herokuapp.com/sendEmail";
//export const brainTreeUrl: 'https://perfect-schedule.herokuapp.com/checkout';

export const DEEP_CLEAN = 'DEEP CLEAN';

export const WINDOW_CLEAN = 'WINDOW CLEANING';

export const WEEKLY = 'WEEKLY';

export const MONTHLY = 'MONTHLY';

export const BIWEEKLY = 'BIWEEKLY';

export const MEMBERSHIP = 'MEMBERSHIP';

export const QUARTERLY_FLOOR_CLEAN = 'QUARTERLY FLOOR CLEANING';

export const SEMI_ANNUAL_FLOOR_CLEAN = 'SEMI-ANNUAL FLOOR CLEANING';

export const ONE_TIME_FLOOR_CLEAN = 'ONE-TIME FLOOR CLEAN';

export const SET_LOADING = 'SET_LOADING';

export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';

export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';

export const SET_IS_FLOOR_CLEAN = 'SET_IS_FLOOR_CLEAN';

export const SET_SERVICE_TYPES = 'SET_SERVICE_TYPES';

export const SET_FUTURE_DATE = 'SET_FUTURE_DATE';
 
export const SET_DISCOUNT = 'SET_DISCOUNT_OBJECT';

export const SET_CUSTOMER = 'SET_CUSTOMER';

export const SET_SERVICE = 'SET_SERVICE';
 
export const ignoreLat = '33.3718';

export const ignoreLng = '-111.823';

export const minSquareFeet = 1500;

export const maxSquareFeet = 8000;

export const onlyShowAvailableDays = true;

export const bookedMessage = "Your appointment has been booked";

export const emailRecipient = "hello@theperfectclean.com";

export const emailSender = 'hello@theperfectclean.com';

export const emailSubject = 'You have a new customer';

export const emailText = 'An appointment has been made';

export const invalidZipMessage = "Sorry but we're currently not servicing your area. We will let you know when we make it to your area.";

export const maxDistance = 30;

export const maxDistanceFromPreviousJob = 10;

export const referrals = [
    "N/A",
    "the_aesthetician",
    "nicolecarlsonxo",
    "alwaysmeliss",
    "saragailvickers",
    "karelytips",
    "nestor_aguilar323",
    "glambycandy_",
    "anniesforgetmeknots",
    "thejadebennett",
    "paigechristensen",
    "kristenforgione",
    "kendy.du",
    "classyclutter"];

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

export const zips =  
[
"85320",
"85086", 
"85322", 
"85323", 
"85392", 
"85396", 
"85326", 
"85377",
"85286", 
"85224", 
"85225", 
"85226", 
"85248", 
"85249", 
"85335", 
"85264",
"85268", 
"85337", 
"85295",
"85296", 
"85297", 
"85298", 
"85233", 
"85234", 
"85301", 
"85302", 
"85303", 
"85304", 
"85305", 
"85306", 
"85307", 
"85308", 
"85309", 
"85338", 
"85395", 
"85340",
"85201", 
"85202", 
"85203", 
"85204",
"85205", 
"85206", 
"85207", 
"85208", 
"85209", 
"85210", 
"85212", 
"85213", 
"85215", 
"85343", 
"85253", 
"85345", 
"85381", 
"85382", 
"85383", 
"85353",
"85339",
"85331", 
"85310", 
"85254", 
"85003", 
"85004", 
"85006", 
"85007", 
"85008", 
"85009",
"85012", 
"85013", 
"85014", 
"85015", 
"85016", 
"85017", 
"85018",
"85019", 
"85020", 
"85021", 
"85022", 
"85023", 
"85143",
"85024", 
"85027", 
"85028", 
"85029", 
"85031", 
"85032", 
"85033", 
"85034", 
"85035", 
"85037", 
"85040", 
"85041", 
"85042", 
"85043",
"85044", 
"85045", 
"85048", 
"85050", 
"85051", 
"85053", 
"85054",
"85083", 
"85085", 
"85142", 
"85263", 
"85266", 
"85255", 
"85256", 
"85257", 
"85258", 
"85259", 
"85260", 
"85262", 
"85250", 
"85251",
"85373", 
"85351",
"85375", 
"85374",
"85387", 
"85388", 
"85379", 
"85281", 
"85282", 
"85283", 
"85284", 
"85217",
"85218",
"85219",
"85118",
"85144",
"85016",
"85140"
];