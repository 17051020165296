import React, {Fragment} from 'react'
import { useEffect, useContext } from 'react'
import MyContext from '../../context/MyContext'
import * as Constants from '../../Constants'
import AppointmentInput from '../appointmentFormLogic/AppointmentInput'
import ContactInput from '../contactFormLogic/ContactInput'

const BiweeklyForm = () => {
  const {serviceTypes, fetchServiceTypes, customer} = useContext(MyContext)
  
  useEffect(() => {
    if(serviceTypes.length == 0){
      fetchServiceTypes(Constants.BIWEEKLY);
    }
  }, [])


  return (
    <Fragment>
      {!customer.customerID ? <ContactInput title={'Bi-Weekly Services'} description={`Regular cleaning, great for busy families!`} /> : <AppointmentInput  title={'Bi-Weekly Services'} description={`Regular cleaning, great for busy families!`}/>}
    </Fragment>
  )
}

export default BiweeklyForm