import * as Constants from '../Constants'
/*
    Description: Standard ContextReducer setup
*/
const contextReducer = (state, action) => {
    switch(action.type){
        case Constants.SET_SERVICE_TYPE:
            return{
                ...state,
                serviceType: action.payload
            }
        case Constants.SET_SELECTED_SERVICE:
            return{
                ...state,
                selectedService: action.payload
            }
        case Constants.SET_FUTURE_DATE:
            return{
                ...state,
                futureDate: action.payload,
            }
        case Constants.SET_IS_FLOOR_CLEAN:
            return{
                ...state,
                isFloorClean: action.payload,
            }
        case Constants.SET_SERVICE_TYPES:
            return{
                ...state,
                serviceTypes: action.payload,
                distinctAreas: Object.keys(action.payload)
            }
        case Constants.SET_DISCOUNT:
            return{
                ...state,
                discountObject: action.payload
            }
        case Constants.SET_LOADING:
            return{
                ...state,
                loading: action.payload
            }
        case Constants.SET_CUSTOMER:
            return{
                ...state,
                customer: action.payload
            }
        default:
            return state
    }
}

export default contextReducer;