import React, { Fragment } from 'react'
import { useEffect, useContext } from 'react'
import MyContext from '../../context/MyContext'
import * as Constants from '../../Constants'
import AppointmentInput from '../appointmentFormLogic/AppointmentInput'
import ContactInputDeepClean from '../contactFormLogic/ContactInputDeepClean'

const OneTimeDeepCleanAds = () => {
  const {serviceTypes, fetchServiceTypes, customer} = useContext(MyContext)

  useEffect(() => {
    if(serviceTypes.length == 0){
      fetchServiceTypes(Constants.DEEP_CLEAN);
    }
  }, [])

  return (
    <Fragment>
      {!customer.customerID ? <ContactInputDeepClean title={'One Time Deep Clean'} description={'Our most popular one time service!'}/> : <AppointmentInput title={'One Time Deep Clean'} description={'Our most popular one time service!'}/>}
    </Fragment>
  )
}

export default OneTimeDeepCleanAds