import * as Constants from '../../Constants';
/*
    Description: Helper functions used by ContactInput component
*/
export const calculateSquareFeet = sqFeet => {
    sqFeet = Number(sqFeet);
    if (sqFeet < 1001) {
        return 1000;
    } else if (sqFeet > 7999) {
        return 8000;
    }
    let rounded = 250 * Math.round(sqFeet / 250);
    return rounded;
}

export const buildFormData = (fullName, 
        addressObject, 
        phone, 
        email, 
        isFloorCleanType, 
        selectedAreas, 
        roundedSquareFeet, 
        service,
        discount,
        referralSource) => {
    let formData = new FormData();
    formData.set("dataMain[0][CustomerID]", Math.floor(Date.now() / 1000));
    formData.set("dataMain[0][CustomerName]", fullName);
    formData.set("dataMain[0][CustomerAddress]", addressObject.streetAddress);
    formData.set("dataMain[0][CustomerCity]", addressObject.city);
    formData.set("dataMain[0][CustomerZipCode]", addressObject.zip);
    formData.set("dataMain[0][CustomerState]", addressObject.state);
    formData.set("dataMain[0][CustomerPhone1]", phone);
    formData.set("dataMain[0][CustomerEmail]", email);
    formData.set("dataMain[0][SquareFt]", isFloorCleanType ? Number(selectedAreas) : roundedSquareFeet);
    formData.set("dataMain[0][Frequency]", service.frequency);
    formData.set("dataMain[0][ServiceType]", service.description);
    formData.set("dataMain[0][Price]", service.defaultCharge);
    formData.set("dataMain[0][InitialQuote]", service.defaultInitialCharge);
    if(discount.valid){
        if(discount.type === 'p'){
            formData.set("dataMain[0][InitialDiscount]", (service.defaultInitialCharge * (discount.amount / 100)));
        }else if(discount.type === 'a'){
            formData.set("dataMain[0][InitialDiscount]", (discount.amount));
        }else{
            formData.set("dataMain[0][InitialDiscount]", 0);
        }
    }else{
        formData.set("dataMain[0][InitialDiscount]", 0);
    }
    formData.set("dataMain[0][CustomerStatus]", "ACT");
    formData.set("dataMain[0][Lat]", Number(addressObject.lat));
    formData.set("dataMain[0][Lng]", Number(addressObject.lng));
    formData.set("dataMain[0][Lead]", "Yes");
    if(referralSource !== ''){
        formData.set("dataMain[0][CustomerSource]", referralSource);
    }else{
        formData.set("dataMain[0][CustomerSource]", "Online");
    }

    return formData;
}

export const calculateRecurringVariable = serviceType => {
    let term = '', startingCount = 0;
        if(serviceType.toUpperCase() === Constants.WEEKLY){
            term = "Weekly";
            startingCount = '7';
        }else if(serviceType.toUpperCase() === Constants.BIWEEKLY){
            term = "Bi-Weekly";
            startingCount = '14';
        }else if(serviceType.toUpperCase() === Constants.MONTHLY){
            term = "Monthly";
            startingCount = '30';
        }else if(serviceType.toUpperCase() === Constants.QUARTERLY_FLOOR_CLEAN){
            term = "Quarterly";
            startingCount = '90';
        }else if(serviceType.toUpperCase() === Constants.SEMI_ANNUAL_FLOOR_CLEAN){
            term = "Semi-Annual";
            startingCount = '180';
        }
        return({term, startingCount});
}