import * as Constants from '../../Constants';
/*
    Description: Helper functions used by the AppointmentInput component to keep it cleaner
*/

export const isOneTimeCharge = serviceType => {
    if(serviceType === Constants.DEEP_CLEAN || serviceType === Constants.ONE_TIME_FLOOR_CLEAN){
        return true;
    }
    return false;
}

export const isAfternoon = spot => {
    let splitTime = spot.start.split(":");
    let hours = splitTime[0];
    return hours > 11;
}

export const isWeekDay = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
}

export const getFormattedDate = date => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
    month = `0${month}`;
    }
    if (day < 10) {
    day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
}

export const getFormattedTime = spot => {
    let splitTime = spot.start.split(":");
    let pm = isAfternoon(spot);
    let finalString = `${
      splitTime[0] > 12 ? splitTime[0] - 12 : splitTime[0]
    }:${splitTime[1]} ${pm ? "PM" : "AM"}`;
    return finalString;
}

export const paramBuilder = dataObj => {
    let urlParams = '';
    let dataArr = Object.entries(dataObj);
    dataArr.forEach((entry, index) => {
        if (entry[1] !== "") {
        if (index === 0) {
            urlParams += "&";
        }
        urlParams += `${entry[0]}=${entry[1]}`;
        if (index < dataArr.length - 1) {
            urlParams += "&";
        }
        }
    });

    return urlParams;
}

export const calculateFinishTime = (hourString, duration) => {
    let hoursToAdd = Math.floor(duration / 60);
    let minutesToAdd = duration % 60;
    let splitStart = hourString.split(':');
    let hours = Number(splitStart[0]) + Number(hoursToAdd);
    let minutes = Number(splitStart[1]) + Number(minutesToAdd);
    if(minutes >= 60){
        hours = hours + Math.floor(Number(minutes / 60));
        minutes = Number(minutes % 60);
    }
    let finishTime = `${hours}:${minutes}`;
    return finishTime;
}

export const validateSpotTime = (spot, selectedService) => {
    let ignoreDistanceToNext = spot.nextLat === Constants.ignoreLat && spot.nextLng === Constants.ignoreLng;

    if(spot.start ===  "09:00:00"){
        if(Number(spot.distanceToPrevious) > Constants.maxDistance){
          return false;
        }
    }else if (Number(spot.distanceToPrevious) > Constants.maxDistanceFromPreviousJob) {
        return false;
    }else if(!ignoreDistanceToNext && (Number(spot.distanceToNext) > Constants.maxDistanceFromPreviousJob)){
        return false;
    }

    //second validation step, if finish time exceeds 5:00pm then return false
    let finishTime = calculateFinishTime(spot.start, selectedService.defaultLength);
    let splitFinish = finishTime.split(':');
    let hours = Number(splitFinish[0]);
    let minutes = Number(splitFinish[1]);
    if(minutes >= 60){
        hours = Number(hours + Math.floor(Number(minutes / 60)));
        minutes = Number(minutes % 60);
    }
    if(hours > 17){
        return false;
    }else if(hours === 17 && minutes > 0){
        return false;
    }

    //third validation, don't schedule past 2
    let splitStart = spot.start.split(':');
    let startHour = Number(splitStart[0]);
    let startMinute = Number(splitStart[1]);
    if(startMinute >= 60){
        startHour = startHour + Math.floor(startMinute / 60);
        startMinute = startMinute % 60;
    }
    if(startHour > 14){
        return false;
    }else if(startHour === 14 && startMinute > 0){
        return false;
    }

    return true;
}

export const validateSpot = (spot, spotsToExclude, selectedService) => {
    //first validation step, check if spot should be excluded
    if(spotsToExclude.indexOf(spot.spotId) !== -1){
        return false;
    }

    //second and third validations
    if(!validateSpotTime(spot, selectedService)){
        return false;
    }

    //fourth validation step, check for any conflicting future spots
    let duration = selectedService.defaultLength;
    let startingId = spot.spotID;
    let capacity = spot.spotCapacity == 0 ? 19 : spot.spotCapacity;
    let range = (Math.ceil(duration / capacity));
    for (let x = 0; x < range; x++) {
        if (spotsToExclude.indexOf(startingId.toString()) !== -1) {
            return false;
        }
        startingId++;
    }
    
    return true;
}