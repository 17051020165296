import React, { Fragment } from 'react'
import { useEffect, useContext } from 'react'
import MyContext from '../../context/MyContext'
import * as Constants from '../../Constants'
import AppointmentInput from '../appointmentFormLogic/AppointmentInput'
import ContactInputFloorClean from '../contactFormLogic/ContactInputFloorClean'

const OneTimeFloorCleanAds = () => {
  const {serviceTypes, fetchServiceTypes, customer} = useContext(MyContext)

  useEffect(() => {
    if(serviceTypes.length == 0){
      fetchServiceTypes(Constants.ONE_TIME_FLOOR_CLEAN);
    }
  }, [])

  return (
    <Fragment>
      {!customer.customerID ? <ContactInputFloorClean title={'One Time Floor Clean'} description={`Clean as many areas as you need`}/> : <AppointmentInput title={'One Time Floor Clean'} description={`Clean as many areas as you need`}/>}
    </Fragment>
  )
}

export default OneTimeFloorCleanAds