import React from 'react'
import { useContext } from 'react'
import MyContext from '../context/MyContext'

const Header = () => {
    const {serviceType, futureDate} = useContext(MyContext)
  return (
      <div className="header-container">
                  <div className="imgContainer">
                    <img src="https://theperfectclean.com/wp-content/uploads/2019/03/logo-1.png" alt="Header Image" className='w-100 h-auto'/>
          </div>
      </div>
  )
}

export default Header