import React, { Fragment } from 'react'
import { useEffect, useContext } from 'react'
import MyContext from '../../context/MyContext'
import * as Constants from '../../Constants'
import WindowCleanAppointmentInput from '../appointmentFormLogic/WindowCleanAppointmentInput'
import ContactInputWindowClean from '../contactFormLogic/ContactInputWindowClean'

const WindowCleaning = () => {
  const {serviceTypes, fetchWindowService, customer} = useContext(MyContext)

  useEffect(() => {
    if(serviceTypes.length == 0){
      fetchWindowService(Constants.WINDOW_CLEAN);
    }
  }, [])

  return (
    <Fragment>
      {!customer.customerID ? <ContactInputWindowClean title={'SEE PRICING'} description={'Get your discount'}/> : <WindowCleanAppointmentInput/>}
    </Fragment>
  )
}

export default WindowCleaning;