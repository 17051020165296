import * as Helpers from './Helpers';
import * as Constants from '../../Constants';
import axios from 'axios'

/*
    Description: HTML code snippets used by the AppointmentInput component to keep it cleaner
*/

export const getInitialPrice = (discountObject, selectedService) => {
    if(discountObject.valid !== false && discountObject.amount > 0 && discountObject.type !== ''){
        return(
            <div className="inline-block">
                <s>{selectedService.defaultInititalCharge}</s>
                {discountObject.type === 'p' ? 
                    <span className="text-danger">${(selectedService.defaultInitialCharge - (selectedService.defaultInitialCharge * (discountObject.amount/100))).toFixed(2)}</span>
                    : discountObject.type === 'a' ?
                    <span className="text-danger">${(selectedService.defaultInitialCharge - discountObject.amount).toFixed(2)}</span>
                    : null
                }
            </div>
        )
    }
    return(
        <div className="d-inline-block">${selectedService.defaultInitialCharge}</div>
    )
}

export const getErrors = errors => {
    if(errors.length === 0){
        return null;
    }

    return (
        <div>
            {errors.map((error, index) => {
                return (
                    <p key={index} className="error">{error}</p>
                )
            })}
        </div>
    )
}

export const getAvailableTimeSlots = (morning, morningSpots, afternoonSpots, selectedSpot, firstSlotOpen, setSelectedSpot) => {
    let newTimesObj = morning ? morningSpots : afternoonSpots;
    let title = morning ? "Morning" : "Afternoon";
    let openSlotsArr = [];

    if(morning){
        if(firstSlotOpen){
            newTimesObj = {'09:00:00' : newTimesObj["09:00:00"]};
        }
    }else{
        if(firstSlotOpen){
           newTimesObj = {};
        }
    }

    if (Object.keys(newTimesObj).length > 0){
        Object.entries(newTimesObj).forEach(timeSlotObj => {
            let timeSlotArr = timeSlotObj[1];
            if (timeSlotArr.length > 0){
                let index = 0;
                for (index; index < timeSlotArr.length; index++){
                    let timeSlot = timeSlotArr[index];
                    if(timeSlot.open === '1'){
                        openSlotsArr.push(timeSlot);
                    }
                    break;
                }
            }
        });
        var compare = (a,b) => {
            const startA = a.start;
            const startB = b.start;
            let comparison = 0;
            if(startA > startB){
                comparison = 1
            }else if(startA < startB){
                comparison = -1;
            }
            return comparison;
        }
        openSlotsArr.sort(compare);

        return (
            <div>
                {openSlotsArr.length > 0  && <h3 className='timeOfDay'>{title}</h3>}
                {openSlotsArr.map((spot, index) => {
                    console.log(selectedSpot.spotId)
                    console.log(spot)
                    let isActiveButton = selectedSpot.spotID === spot.spotID;
                    let buttonClass = isActiveButton ? "appointmentBtn activeAppointmentButton" : "appointmentBtn";
                    return (
                        <input
                            key={index}
                            type='button'
                            className={buttonClass}
                            value={Helpers.getFormattedTime(spot)}
                            onClick={() => setSelectedSpot(spot)}
                        />
                    )
                })}
            </div>
        )
    }
    return <div/>

}

export const sendEmail = (customer, selectedService) =>{
    console.log('sending email');
    console.log(customer);
    console.log(selectedService)
    let dataObj = {
        recipient: Constants.emailRecipient,
        sender: Constants.emailSender,
        subject: Constants.emailSubject,
        text: Constants.emailText,
        customer: customer,
        service: selectedService
    }

    axios.post(`${Constants.baseUrl}/sendEmail`, dataObj)
    .then(res => console.log(res));
}