import React from 'react'
import { useState, useContext } from 'react';
import ReactPixel from 'react-facebook-pixel';
import MyContext from '../../context/MyContext';
import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from "cleave.js/react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import AddressInput from './AddressInput';
import * as Constants from '../../Constants'
import { buildFormData, calculateSquareFeet } from './Helpers';
import axios from 'axios';
import Loading from '../Loading';

/*
    Description: First half of the process, once complete it creates a customer object and sets it in context.
        The form component will then switch over to the appointmentInput form.

    Notes:
    - Uses Helper.js for helper functions to keep this cleaner

    - Address prediction input is in AddressInput.js file
*/



const ContactInput = ({ title, description, hideAreas, giveawayType, hideSource}) => {
    const { serviceType, isFloorClean, 
        distinctAreas, discountObject, 
        serviceTypes, setContext, loading} = useContext(MyContext);
    const [fullName, setFullName] = useState('');
    const [addressObject, setAddressObject] = useState({fullAddress: '',});
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [squareFeet, setSquareFeet] = useState('');
    const [selectedAreas, setSelectedAreas] = useState('');
    // Removing hide areas so that the referral source is set if there is a giveaway Type.
    const [referralSource, setReferralSource] = useState(giveawayType ? giveawayType : '');
    const [hideDropdown, setHideDropdown] = useState(hideSource ? true : false);


    /*
        Main function that makes all the necessary API calls to create the customer
        calls several helper functions in Helpers file
    */
    const createCustomer = (e) => {
      

        e.preventDefault();
        if(
            fullName === '' || phone === '' || email === '' || addressObject.fullAddress === '' || squareFeet === '' ||
            (!isFloorClean && squareFeet && !hideAreas === '') ||
            (isFloorClean && selectedAreas && !hideAreas === '')
        ){
            alert('Please fill out all the fields');
        }else{
            setContext(Constants.SET_LOADING, true);
            let service = {};
            let roundedSquareFeet = 0;

            //grab the correct service type based in input of square feet or selected area
            if(isFloorClean){
                if(serviceTypes[selectedAreas]){
                    service = serviceTypes[selectedAreas][0];
                }
            } else {
                let sqFeet = squareFeet.replace(/,/g, "");
                roundedSquareFeet = calculateSquareFeet(sqFeet);

                if(serviceTypes[roundedSquareFeet]){
                    let tmpArr = serviceTypes[roundedSquareFeet].filter(
                        service => service.category === serviceType.toUpperCase()
                    );
                    service = tmpArr[0];
                }
            }
            setContext(Constants.SET_SELECTED_SERVICE, service);
            //console.log('selected service');
            //console.log(service);


            //validate discount
            let tmpDiscount = discountObject
            if(discountObject.valid && discountObject.type === 'a'){
                if(discountObject.amount > service.defaultInitialCharge){
                    tmpDiscount = {type: null, amount: null, valid: false}
                    setContext(Constants.SET_DISCOUNT, tmpDiscount);
                }
            }

            //build form data from inputs
            let formData = buildFormData(
                fullName, 
                addressObject, 
                phone, 
                email, 
                isFloorClean, 
                selectedAreas, 
                roundedSquareFeet, 
                service, 
                tmpDiscount,
                referralSource);
               

            let postUrl = `${Constants.routesUrl}/main`;

            let obj = {};
            formData.forEach(function(value, key){
                obj[key] = value;
            });

            axios.post(postUrl, obj, {headers: { "Content-Type": "application/json" }})
            .then(res => {
                let data = res.data;
                //api should create a customer and return a customerID, use it to retrieve customer
                if(data.customersImported && data.customersImported.length > 0){
                    let customer = data.customersImported[0];
                    return axios.post(`${Constants.routesUrl}/customer/${customer.PestRoutesCustomerID}`);
                }
            })
            .then(res => {
                let data = res.data;
                //console.log('customer response');
                //console.log(data);
                //we now have the customer, set it to state and move onto the next step
                if(data.customer){
                    if(Constants.zips.indexOf(addressObject.zip) === -1){
                        window.location.assign("http://theperfectclean.com/out-of-area/")
                    }else{
                        let customer =  data.customer;
                        //set customer to state
                        setContext(Constants.SET_CUSTOMER, customer);
                    }
                }
            })
            .catch(err => {
                setContext(Constants.SET_LOADING, false);
                alert(`Error creating customer profile: ${err}`)
            })
        }
    }


    const renderCorrectHtml = (s) => {
        if(s === 'ONE-TIME FLOOR CLEAN' && !hideAreas) return (
            <>
            <label className="inputFields_Label">Select # of areas</label>
            <Dropdown
                className='inputFields'
                value={selectedAreas}
                options={distinctAreas}
                placeholder='Areas to be cleaned'
                onChange={(e) => setSelectedAreas(e.value)}
             />
            </>
        )
        if(hideAreas) return null;
        return (
            <>
            <label className="inputFields_Label">Sqft of home</label>
            <Cleave
                className='inputFields'
                placeholder='Square Feet Of Home'
                name='squareFeet'
                type='tel'
                value={squareFeet}
                options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                onChange={(e) => setSquareFeet(e.target.value)}
                
            />  
            </>
        )
    }


    // html output
  return (
    <>
    
    
    <div className="service-selection">
        <img src="https://theperfectclean.com/wp-content/uploads/2023/12/Scheduler_header.png" class="service-image" alt="" />
        <div className="service-image-container"></div>
        <div className="service-information">
            {/* <img className="service-rating" src="https://theperfectclean.com/wp-content/uploads/2023/09/stars.webp" alt="" /> */}
            {/* <p className="serviceTitle">{title}</p>
            <p className="service-description">{description}</p> */}
        </div>
    </div>

    <form className='formEl row text-center'>
        {loading ? <Loading/> :
        <div className='createContainer'>
            <label className="inputFields_Label" htmlFor='name'>Your Full Name</label>
            <input className='inputFields' type="text" name="sName" placeholder='Full Name' 
                value={fullName} onChange={(e) => setFullName(e.target.value)}/>
            
            <label className="inputFields_Label">Your Address</label>
            <AddressInput addressObject={addressObject} setAddressObject={setAddressObject}/>
            
            <label className="inputFields_Label" htmlFor="phone">Phone Number*</label>
            <Cleave
                id="phone"
                className='inputFields'
                placeholder='Enter phone number'
                type="tel"
                name='phone'
                value={phone}
                options={{phone: true, phoneRegionCode: 'US'}}
                onChange={(e) => setPhone(e.target.value)}
            />
            
            <label className="inputFields_Label" htmlFor="email">Email Address</label>
            <input className='inputFields' type="email" name="email" placeholder='Email Address'
                value={email} onChange={(e) => setEmail(e.target.value)}/>

                {renderCorrectHtml(serviceType)}
            
            {/* {isFloorClean ? 
                <Dropdown
                    className='inputFields'
                    value={selectedAreas}
                    options={distinctAreas}
                    placeholder='Areas to be cleaned'
                    onChange={(e) => setSelectedAreas(e.value)}
                />
                : <Cleave
                    className='inputFields'
                    placeholder='Square Feet Of Home'
                    name='squareFeet'
                    type='tel'
                    value={squareFeet}
                    options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                    onChange={(e) => setSquareFeet(e.target.value)}
                /> 
               
            } */}
            {!hideDropdown &&
            <>
            <label className={`inputFields_Label`}>How did you hear about us?</label>
                <Dropdown
                    disabled={hideAreas ? true : false}
                    className={`inputFields`} 
                    placeholder='How did you hear about us'
                    options={Constants.referrals}
                    value={referralSource}
                    hidden={true}
                    onChange={ (e) => setReferralSource(e.value)}
                />
            </>
            }
            <div className="orderSummaryConfirmContainer">
                <input type="button" value="Next" className="createCustomer" onClick={createCustomer}/>
                <p className="contact-description">
                I agree to receive marketing messaging from The Perfect Clean at the phone number provided above. I understand I will receive 2 messages a month, data rates may apply, Text HELP to 480-907-3402 for assistance, reply STOP to opt out
                </p>

                <div className="tos-container">
                    <a href="https://theperfectclean.com/terms-and-conditions/">Terms of Service</a> 
                    <a href="https://theperfectclean.com/privacy-policy/">Privacy Policy</a> 
                </div>
            </div>
        </div>}
    </form>
    </>
  )
}

export default ContactInput