import React from 'react'
import { useState, useContext } from 'react';
import ReactPixel from 'react-facebook-pixel';
import MyContext from '../../context/MyContext';
import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from "cleave.js/react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import AddressInput from './AddressInput';
import * as Constants from '../../Constants'
import { buildFormData, calculateSquareFeet } from './Helpers';
import axios from 'axios';
import Loading from '../Loading';

/*
    Description: First half of the process, once complete it creates a customer object and sets it in context.
        The form component will then switch over to the appointmentInput form.

    Notes:
    - Uses Helper.js for helper functions to keep this cleaner

    - Address prediction input is in AddressInput.js file
*/



const ContactInput = ({ title, description, hideAreas, giveawayType}) => {
    const { serviceType, isFloorClean, 
        distinctAreas, discountObject, 
        serviceTypes, setContext, loading} = useContext(MyContext);
    const [fullName, setFullName] = useState('');
    const [addressObject, setAddressObject] = useState({fullAddress: '',});
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [squareFeet, setSquareFeet] = useState('');
    const [selectedAreas, setSelectedAreas] = useState('');
    const [referralSource, setReferralSource] = useState('Facebook Ads');

    /*
        Main function that makes all the necessary API calls to create the customer
        calls several helper functions in Helpers file
    */
    const createCustomer = (e) => {
      

        e.preventDefault();
        if(
            fullName === '' || phone === '' || email === '' || addressObject.fullAddress === '' ||
            (!isFloorClean && squareFeet && !hideAreas === '') ||
            (isFloorClean && selectedAreas && !hideAreas === '')
        ){
            alert('Please fill out all the fields');
        }else{
            setContext(Constants.SET_LOADING, true);
            let service = {};
            let roundedSquareFeet = 0;

            //grab the correct service type based in input of square feet or selected area
            if(isFloorClean){
                if(serviceTypes[selectedAreas]){
                    service = serviceTypes[selectedAreas][0];
                }
            } else {
                let sqFeet = squareFeet.replace(/,/g, "");
                roundedSquareFeet = calculateSquareFeet(sqFeet);

                if(serviceTypes[roundedSquareFeet]){
                    let tmpArr = serviceTypes[roundedSquareFeet].filter(
                        service => service.category === serviceType.toUpperCase()
                    );
                    service = tmpArr[0];
                }
            }
            // setContext(Constants.SET_SELECTED_SERVICE, service);
            //console.log('selected service');
            //console.log(service);


            //validate discount
            let tmpDiscount = discountObject
            if(discountObject.valid && discountObject.type === 'a'){
                if(discountObject.amount > service.defaultInitialCharge){
                    tmpDiscount = {type: null, amount: null, valid: false}
                    setContext(Constants.SET_DISCOUNT, tmpDiscount);
                }
            }

            //build form data from inputs
            let formData = buildFormData(
                fullName, 
                addressObject, 
                phone, 
                email, 
                isFloorClean, 
                selectedAreas, 
                roundedSquareFeet, 
                service, 
                tmpDiscount,
                referralSource);
               

            let postUrl = `${Constants.routesUrl}/main`;

            let obj = {};
            formData.forEach(function(value, key){
                obj[key] = value;
            });

            axios.post(postUrl, obj, {headers: { "Content-Type": "application/json" }})
            .then(res => {
                let data = res.data;
                //api should create a customer and return a customerID, use it to retrieve customer
                if(data.customersImported && data.customersImported.length > 0){
                    let customer = data.customersImported[0];
                    return axios.post(`${Constants.routesUrl}/customer/${customer.PestRoutesCustomerID}`);
                }
            })
            .then(res => {
                let data = res.data;
                //console.log('customer response');
                //console.log(data);
                //we now have the customer, set it to state and move onto the next step
                if(data.customer){
                    if(Constants.zips.indexOf(addressObject.zip) === -1){
                        window.location.assign("http://theperfectclean.com/out-of-area/")
                    }else{
                        let customer =  data.customer;
                        //set customer to state
                        setContext(Constants.SET_CUSTOMER, customer);
                    }
                }
            })
            .catch(err => {
                setContext(Constants.SET_LOADING, false);
                alert(`Error creating customer profile: ${err}`)
            })
        }
    }


    const renderCorrectHtml = (s) => {
        if(s === 'WINDOW CLEANING' && !hideAreas) return (
            <>
            <label className="inputFields_Label">Select # of areas</label>
            <Dropdown
                className='inputFields'
                value={selectedAreas}
                options={distinctAreas}
                placeholder='Areas to be cleaned'
                onChange={(e) => setSelectedAreas(e.value)}
             />
            </>
        )
        if(hideAreas) return null;
        return (
            <>
            <label className="inputFields_Label">Sqft of home</label>
            <Cleave
                className='inputFields'
                placeholder='Square Feet Of Home'
                name='squareFeet'
                type='tel'
                value={squareFeet}
                options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                onChange={(e) => setSquareFeet(e.target.value)}
            />  
            </>
        )
    }


    // html output
  return (
    <form className='formEl row text-center'>
        <h1 className="header">{title}</h1>
        <p className="paragraph">{description}</p>
        {loading ? <Loading/> :
        <div className='createContainer'>
            <label className="inputFields_Label" htmlFor='name'>Your full name (Required)</label>
            <input className='inputFields' type="text" name="sName" placeholder='Full Name' 
                value={fullName} onChange={(e) => setFullName(e.target.value)}/>
            
            <label className="inputFields_Label">Your address (Required)</label>
            <AddressInput addressObject={addressObject} setAddressObject={setAddressObject}/>
            
            <label className="inputFields_Label" htmlFor="phone">Phone Number (Required)</label>
            <Cleave
                id="phone"
                className='inputFields'
                placeholder='Enter phone number'
                type="tel"
                name='phone'
                value={phone}
                options={{phone: true, phoneRegionCode: 'US'}}
                onChange={(e) => setPhone(e.target.value)}
            />

            <label className="inputFields_Label" htmlFor="email">Email Address (Required)</label>
            <input className='inputFields' type="email" name="email" placeholder='Email Address'
                value={email} onChange={(e) => setEmail(e.target.value)}/>

                {/* {renderCorrectHtml(serviceType)} */}
            <p className="deposit-message-text">A $100 deposit is taken when you schedule. This deposit gets applied to your service. This covers your first 10 windows.</p>
            
            {/* {isFloorClean ? 
                <Dropdown
                    className='inputFields'
                    value={selectedAreas}
                    options={distinctAreas}
                    placeholder='Areas to be cleaned'
                    onChange={(e) => setSelectedAreas(e.value)}
                />
                : <Cleave
                    className='inputFields'
                    placeholder='Square Feet Of Home'
                    name='squareFeet'
                    type='tel'
                    value={squareFeet}
                    options={{numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                    onChange={(e) => setSquareFeet(e.target.value)}
                /> 
               
            } */}
           { fullName === '' || phone === '' || email === '' || addressObject.fullAddress === '' || squareFeet === '' ||
                (!isFloorClean && squareFeet && !hideAreas === '') ||
                (isFloorClean && selectedAreas && !hideAreas === '') ?
            <div className="btnContainer">
                <p className="next">Please fill out the rest of the information</p>
            </div>
            :
              <div className="orderSummaryConfirmContainer">
                <input type="button" value="Get Pricing" className="completeOrder" onClick={createCustomer}/>
              </div>

            }
              <Dropdown
                disabled={hideAreas ? true : false}
                className='inputFields invisible' 
                placeholder='How did you hear about us'
                options={['Facebook Ads']}
                value={'Facebook Ads'}
            />
        </div>}
    </form>
  )
}

export default ContactInput